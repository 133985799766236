import Flickity from 'flickity';
import _ from 'lodash';

const carouselContainers = document.querySelectorAll('[data-carousel-container]');
const carousel = () => {
  _.forEach(carouselContainers, function (carouselContainer) {
    const carousel = carouselContainer.querySelectorAll('[data-carousel]')[0];
    const carouselBounding = carousel.getBoundingClientRect();
    const carouselAmount = carousel.getAttribute('data-carousel');
    const carouselItems = carousel.querySelectorAll('[data-carousel-item]');
    const isInCarousel = (elem) => {
      const bounding = elem.getBoundingClientRect();
      return (
        bounding.left >= carouselBounding.left &&
        bounding.right <= (carouselBounding.left + carouselBounding.width)
      );
    };

    const flkty = new Flickity(carousel, {
      pageDots: false,
      prevNextButtons: true,
      cellAlign: 'center',
      contain: true,
      touchVerticalScroll: false,
      draggable: true,
      setGallerySize: true,
      resize: true,
      imagesLoaded: true
    });

  });
}

if (!!carouselContainers) {
  setTimeout(function () {
    carousel();
  }, 1000);
  window.addEventListener("resize", function () {
    carousel();
  });
}




//for hero
if (document.querySelector(".main-carousel")) {
  var heroflkty = new Flickity('.main-carousel', {
    prevNextButtons: false,
    pageDots: true,
    autoPlay: true,
    selectedAttraction: 0.02,
    friction: 0.4,
    wrapAround: true,
    on: {
      ready: function () {
        var height = 0;

        var slideEls = document.querySelectorAll(".carousel-cell")

        slideEls.forEach(function (el) {
          if (el.offsetHeight > height) {
            height = el.offsetHeight
          }
        });

        slideEls.forEach(function (el) {
          el.style.height = height + "px"
        })
      },
      change: function (index) {
        var readMoreEl = document.querySelectorAll(".read-more")
        var activeReadMoreEl = document.querySelector(".is-selected .read-more")
        readMoreEl.forEach(function (el) {
          if (!el.classList.contains("opacity-0")) {
            el.classList.add("opacity-0")
          }
        })

        activeReadMoreEl.classList.remove("opacity-0")
      }
    }
  });
}

//for testimonials
if (document.querySelector(".testimonial-carousel")) {
  var testimonialflkty = new Flickity('.testimonial-carousel', {
    prevNextButtons: true,
    pageDots: false,
    autoPlay: true,
    selectedAttraction: 0.02,
    friction: 0.4,
    wrapAround: true,
  });
}