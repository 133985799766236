import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import { configureApi, apiToken, apiUrl, executeQuery } from './searchCommon';

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchResults: [],
    searchSorting: 'postdate desc',
    isLoading: true,
    isReady: false,
    arrowCount: 0,
    isSearchDropdownOpen: false,
    isSearchFocused: false,
    window: {
      width: 0,
      height: 0,
    },
  };
}

let filterChangeTimer;

let generalSearch = function () {
  const searchSite = document.getElementById('generalSearch').dataset.site;

  // What to search for
  const searchEntries = [
    'newsInsights_article_Entry',
    'newsInsights_resource_Entry',
    'people_person_Entry',
    'programs_program_Entry',
    'homepage_homepage_Entry',
    'contact_contact_Entry',
    'about_about_Entry',
    'customDesign_customDesign_Entry',
    'newsInsightsLanding_newsInsightsLanding_Entry',
    'pages_page_Entry',
    'peopleLanding_peopleLanding_Entry',
    'privacyPolicy_privacyPolicy_Entry',
    'programsLanding_programsLanding_Entry',

  ];
  let searchQueries = '';
  _.each(searchEntries, (entryType) => {
    searchQueries =
      searchQueries +
      `
      ... on ${entryType} {
        typeId
        id
        title
        url
        postDate
      }`;
  });

  // The query to search for entries in Craft
  const searchQuery = `
    query searchQuery($needle: String, $limit: Int, $offset: Int, $orderBy: String, $site: [String]) {
      entries( limit: $limit, offset: $offset, orderBy: $orderBy, search: $needle, site: $site) {
        ${searchQueries}
      }
    }
  `;

  const searchHandlers = Array.from(document.querySelectorAll('.general-search'));

  for (let handler in searchHandlers) {
    new Vue({
      el: searchHandlers[handler],
      name: 'general-search',
      delimiters: ['<%', '%>'],
      data: getcategorySearchLandingData(),
      components: {},
      beforeCreate: function () { },
      created: function () {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
      },
      mounted: function () {
        document.addEventListener('click', this.closeSearchDropdown);
      },
      updated: function () { },
      destroyed: function () {
        document.removeEventListener('click', this.closeSearchDropdown);
        window.removeEventListener('resize', this.handleResize);
      },
      watch: {
        searchQuery: function (val, oldVal) {
          this.performSearch();
        },
        isSearchDropdownOpen: function (val, oldVal) {
          if (val && !oldVal) {
            document.addEventListener('keydown', this.arrowCounter);
          } else {
            document.removeEventListener('keydown', this.arrowCounter);
          }
        },
        isLoading: function (val, oldVal) {
          if (!val && oldVal) {
            this.arrowCount = 0;
          }
        },
      },
      filters: {},
      computed: {},
      methods: {
        performSearch() {
          let self = this;

          if (self.searchQuery === '') {
            self.searchResults = [];
            self.isSearchDropdownOpen = false;
            return true;
          }

          self.isLoading = true;
          const searchTitle = !!self.searchQuery
            ? `title:${self.searchQuery}* OR contentMatrix:${self.searchQuery}*`
            : '';
          const searchCategory = !!self.searchQuery
            ? `categories:"${self.searchQuery}" OR categories::"${self.searchQuery}"`
            : '';
          const searchTag = !!self.searchTag
            ? `tags:${self.searchQuery} OR tags::${self.searchQuery}`
            : '';

          const searchString = _.compact([searchTitle, searchCategory, searchTag]).join(' OR ');

          // Set the variables we will pass in to our query
          const variables = {
            needle: searchString,
            limit: 5,
            orderBy: self.searchSorting,
            site: [searchSite],
          };
          // Execute the query
          clearTimeout(filterChangeTimer);

          filterChangeTimer = setTimeout(function () {
            executeQuery(self.searchApi, searchQuery, variables, (data) => {
              const dataPath = data.data;
              self.searchResults = dataPath.entries;
              self.isSearchDropdownOpen = true;

              if (!self.isReady) {
                self.isReady = true;
              }
              self.isLoading = false;
            });
          }, 500);
        },
        closeSearchDropdown: function (event) {
          if (!event.target.classList.contains('letSearchDropdownOpen')) {
            this.isSearchDropdownOpen = false;
          }
        },
        arrowCounter: function (event) {
          const self = this;
          switch (event.which) {
            case 38: // Arrow up
              if (self.arrowCount > 0) {
                self.arrowCount = self.arrowCount - 1;
              }
              break;
            case 40: // Arrow down
              if (self.arrowCount < this.searchResults.length + 1) {
                self.arrowCount = self.arrowCount + 1;
              }
              break;
            case 27: // ESC
              self.arrowCount = 0;
              self.isSearchDropdownOpen = false;
              break;
            case 13: // Enter
              if (self.arrowCount === 0 || self.arrowCount === this.searchResults.length + 1) {
                self.goToSearchLanding();
              } else {
                const selectedUrl = this.searchResults[self.arrowCount - 1];
                window.location.href = selectedUrl.url;
              }
              break;
          }
        },
        goToSearchLanding() {
          window.location.href = searchSite === 'fi' ? '/fi/search?q=' + this.searchQuery : '/search?q=' + this.searchQuery;
        },
        handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
        },
      },
    });
  }
};

document.getElementById('generalSearch') && generalSearch();
