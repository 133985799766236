<template>
  <div class="md:-mx-4" :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
    <div class="absolute w-full h-full pin-x mx-auto flex flex-wrap content-start" style="z-index: -1">
      <card-loading-indicator
        v-for="n in 6"
        :key="n"
        :is-loading="isLoading"
        :is-ready="isReady"
        :section-type="sectionType"
      ></card-loading-indicator>
    </div>
    <div v-if="items.length > 0" item-selector=".item" class="w-full pb-6 flex flex-wrap">
      <div v-for="(item, key) in items" :key="key" class="w-full md:w-1/2 xxl:w-1/3 md:px-3 mb-6">
        <category-search-item
          :item="item"
          :is-loading="isLoading"
          :section-type="sectionType"
        ></category-search-item>
      </div>
    </div>
    <transition name="fadeIn" mode="in-out">
      <template v-if="!isLoading && items.length === 0">
        <h4 class="font-euclid-bold mb-6 leading-slight text-xl font-normal text-grey-dark max-w-2xs mx-auto py-8 text-center">No programmes found</h4>
      </template>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue';

import CategorySearchItem from './CategorySearchItem.vue';
import CardLoadingIndicator from './CardLoadingIndicator.vue';
import { setTimeout } from 'timers';

export default {
  name: 'CategorySearchList',
  props: {
    sectionType: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: [],
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CategorySearchItem,
    CardLoadingIndicator
  },
  watch: {
    items: function(val, oldVal) {
    },
  },
  methods: {
    listMinHeight: function() {
      let rowHeight = this.sectionType[0] === 'events' ? 280 : 240;
      let rowAmount = this.sectionType[0] === 'events' ? 3 : 8/4;
      if ( window.innerWidth < 480 ) {
        rowHeight = this.sectionType[0] === 'events' ? 280 : 340;
        rowAmount = this.sectionType[0] === 'events' ? 3 : 4;
      } else if ( window.innerWidth < 780 ) {
        rowHeight = this.sectionType[0] === 'events' ? 280 : 240;
        rowAmount = this.sectionType[0] === 'events' ? 3 : 8/2;
      }
      return rowAmount * rowHeight + 'px';
    }
  }
};
</script>
