var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading || !_vm.isReady,
            expression: "isLoading || !isReady"
          }
        ],
        staticClass:
          "cardLoader mb-4 pb-4 flex flex-wrap flex-col xxxl:flex-row border-b border-1 border-solid border-grey-light",
        attrs: { "leave-stagger": "500" }
      },
      [
        _c("div", { staticClass: "w-full" }, [
          _c("div", { staticClass: "flex flex-col lg:flex-row -mx-3" }, [
            _c(
              "div",
              { staticClass: "flex flex-row lg:items-top w-full px-3" },
              [
                _c("div", { staticClass: "flex flex-col h-full w-2/5 mr-5" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "block relative object-fit-fallback aspect-ratio-box aspect-ratio-box--4-3"
                    },
                    [
                      _c("div", {
                        staticClass:
                          "cardLoader__image absolute pin-t pin-l w-full h-full block object-cover"
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full flex flex-col pr-3" }, [
                  _c("div", { staticClass: "mb-1" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "pr-1 h-2-5 mt-2 flex align-center text-blue"
                      },
                      [
                        _c("div", {
                          staticClass: "line-micro flex items-center"
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("h3", {
                    staticClass:
                      "line-medium-large heading-3 mb-none text-grey-dark hover:text-grey mt-1-5"
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-auto" }, [
                    _c("div", {
                      staticClass: "line-medium mt-2 flex items-center"
                    })
                  ])
                ])
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }