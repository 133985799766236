/**
* Eye model object
* -----------------------------
*/
function Eye(sel) {
  // dom
  this.eye = document.querySelector(sel);
  this.pupil = this.eye.children[0];

  // widths
  this.ew = this.eye.offsetWidth;
  this.pw = this.pupil.offsetWidth;

  // centered position
  this.cx = this.eye.getBoundingClientRect().right - this.ew/2;
  this.cy = this.eye.getBoundingClientRect().bottom - this.ew/2;
}

Eye.prototype.movePupil = function(r, theta) {
  let x, y;

  if (r > 1) r = 1; // clamp
  r *= (this.ew/2 - this.pw/2); // restrict edge of pupil to edge of eye

  // convert polar to rectangular
  x = r * Math.cos(theta) + (this.ew - this.pw)/2;
  y = r * Math.sin(theta) + (this.ew - this.pw)/2;

  this.pupil.style.transform = 'translateX(' + x + 'px)' +
                                'translateY(' + y + 'px)';
}


/**
 * target = the eye with a pupil
 * distance = the distance the cursor must be to start watching
 */

export default function eyeEffect(target, distance) {
  /**
  * pupil-mouse tracking and draw
  * -----------------------------
  */
  let eye = new Eye(target),
      wrapper = eye.eye,
      R = 0,
      THETA = 0,
      wrapperWidth = wrapper.offsetWidth,
      wrapperHeight = wrapper.offsetHeight,
      cursorOnScreen = false;


  /**
  * update the computed pupil (polar) coordinates given a mouse event
  * treat bbox as circumscribed by a bounding circle for more
  * intuitive pupil movement
  */
  function updateEyes(event) {
    let x, y, bboxWidth, bboxHeight, bbRadius;
    // center x, y
    x = event.clientX - eye.cx;
    y = event.clientY - eye.cy;

    if ((x < distance && x > -distance) && (y < distance && y > -distance) ) {
      cursorOnScreen = true;
      // get bbox bounds
      bboxWidth = wrapperWidth;
      bboxHeight = wrapperHeight;
      bbRadius = Math.sqrt(Math.pow(bboxWidth,2) + Math.pow(bboxHeight, 2)) /2;

      // computer,  theta
      R = Math.sqrt(Math.pow(x,2) + Math.pow(y,2)) / bbRadius;
      THETA = Math.atan2(y,x);
    } else {
      cursorOnScreen = false;
    }

  }

  function returnToNeutral() {
    cursorOnScreen = false;
  }

  /* draw pupil updates on animation frame */
  function draw() {
    window.requestAnimationFrame(draw);

    // reduce R if mouse isn't on screen
    let dr = 0.4;
    if (!cursorOnScreen && R!==0) {
      dr = (Math.abs(R) < 0.01) ? 0.01 : R * dr;
      R -= dr;
    }

    eye.movePupil(R, THETA);

  }
  draw();


  /**
  * Event handlers
  *------------------------------------
  */

  document.addEventListener('mousemove', updateEyes, false);

  /* return eyes to neutral position */
  document.addEventListener('mouseleave', returnToNeutral, false);

  window.addEventListener('resize', function() {
    wrapperWidth = wrapper.offsetWidth;
    wrapperHeight = wrapper.offsetHeight;
  });
};