<template>
  <div
    class="flex flex-wrap justify-between text-center md:text-left font-euclid text-sm xl:text-base"
    :class="sectionType[0] === 'events' || sectionType[0] === 'searchLanding' ? 'mb-0' : 'mb-3'"
  >
    <div
      class="w-2/5 text-left"
      :class="sectionType[0] === 'events' || sectionType[0] === 'searchLanding' ? 'mb-0' : 'mb-3 md:mb-0'"
    >
      <div v-if="itemsAmount > 0">
        <div v-if="withPagination">
          <span class="pr-1 font-euclid-bold text-lg">{{itemOffset + 1}} – {{itemsAmount}}</span>
          <span class="leading-loose">({{totalItems}})</span>
        </div>
        <div v-else>
          <span class="leading-loose text-lg">{{itemsAmount}} results</span>
        </div>
      </div>
      <div v-else>
        <span class="pr-1 font-euclid-bold text-lg">0</span>
        <span class="leading-loose text-lg">results</span>
      </div>
    </div>
    <search-sorting :sorting.sync="searchSorting" :options="sortingOptions"></search-sorting>
  </div>
</template>

<script>
import Vue from 'vue'
import SearchSorting from './SearchSorting.vue'

export default {
  name: 'CategorySearchMeta',
  props: {
    totalItems: {
      type: Number,
      default: 0,
    },
    itemOffset: {
      type: Number,
      default: 0,
    },
    elementsPerPage: {
      type: Number,
      default: 0,
    },
    sectionType: {
      type: Array,
      default: [],
    },
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Newest',
          value: 'postdate desc',
        },
        {
          title: 'Oldest',
          value: 'postdate asc',
        },
        {
          title: 'Title a-z',
          value: 'title asc',
        },
        {
          title: 'Title z-a',
          value: 'title desc',
        },
      ],
    },
  },
  data() {
    return {
      searchSorting: '',
      withPagination: true,
    }
  },
  computed: {
    itemsAmount: function() {
      console.log("totalItems:" + this.totalItems);
      console.log("offset:" + this.itemOffset);
      console.log("elementsperpage:" + this.elementsPerPage);
      const itemsLeft =
        this.totalItems - this.itemOffset > this.elementsPerPage
          ? this.itemOffset + this.elementsPerPage
          : this.totalItems

          if (this.totalItems|length && this.itemOffset === 0 && this.elementsPerPage === 0) {
            this.withPagination = false;
            return this.totalItems
          } else {
            return itemsLeft
          }

    },
  },
  watch: {
    searchSorting: function(val) {
      this.$emit('update:searchSorting', val)
    },
  },
  components: {
    SearchSorting,
  },
}
</script>
