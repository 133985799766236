
export default function plopEffect(target, classes) {

  function isElementInViewport(el) {
    let rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  // animate number counter
  if (document.querySelectorAll(target)) {
    const elements = document.querySelectorAll(target);

    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i];

      window.setInterval(function() {
        if (!element.classList.contains(classes)) {
          if (isElementInViewport(element)) {
            element.classList.add(classes);
          }
        }
      }, 400)
    }
  }
}