// Information needed for connecting to our Craft CMS 4 GraphQL endpoint
export const apiToken = 'kpscqzTYy6FYDdG7nhew_5sxQcflCTGg';
export const apiUrl = '/api'; // Update this if you've changed the GraphQL endpoint in Craft

// Configure the api endpoint
export function configureApi(url, token) {
  return {
    baseURL: url,
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest'
    }
  };
};

// Execute a GraphQL query by sending an XHR to our api endpoint
export function executeQuery(api, query, variables, callback) {
  api.post('', {
    query: query,
    variables: variables
  }).then((result) => {
    if (callback) {
      callback(result.data);
    }
  }).catch((error) => {
    console.error("Error executing GraphQL query:", error); // Log the error for debugging purposes
    // You can add additional error handling here, such as user feedback
  })
};

let query = window.location.search.substring(1);
export function getQueryParam(param) {
  if (query.length === 0) {
    return false;
  }
  let params = decodeURIComponent(query).split('&');
  let results = [];
  for (let i = 0; i < params.length; i++) {
    let pair = params[i].split('=');
    if (decodeURIComponent(pair[0]) === param) {
      results.push(decodeURIComponent(pair[1]))
    }
  }
  if (results.length === 0) { results = ''; }
  if (results.length === 1 && param !== 'cat' && param !== 'cat-id') { results = results[0]; }

  return results;
}
