<template>
  <transition name="fadeIn" mode="in-out">
    <a :href="item.url" class='card-hover program-card flex flex-col bg-beige border border-tan relative w-full min-h-full group rounded-lg' v-show="!isLoading">

    <div class="relative p-6 xxl:p-10 flex flex-col min-h-50 h-full flex-grow">
      <div class="flex items-center flex-wrap mb-3">

        <p v-if="hasProgramCategory" class="text-xs text-black font-euclid-medium font-medium uppercase flex items-center tracking-wide whitespace-no-wrap mb-2 mr-4">
          <span v-for="(cat, index) in item.programCategory" :key="index" class="flex items-center">
            {{ cat.title }}
            <span v-if="index != item.programCategory.length - 1" class="inline-block bg-orange mx-2 w-1 h-1 rounded-full"></span>            
          </span>
        </p>
        <div v-if="hasProgramLabel" class="flex">
          <div
            v-for="(label, index) in item.programLabel"
            :key="index"
            class="mb-2 font-euclid-medium font-medium text-xs uppercase text-white px-2 py-1 tracking-wide relation-card__label bg-black whitespace-no-wrap"
            >
            {{label.title}}
          </div>
        </div>        
      </div>

        <h3 class="font-euclid-medium font-medium text-2xl xl:text-3xl text-black mb-5">{{ item.title }}</h3>

        <p v-if="!!item.descriptionCard" class='text-base leading-loose text-text mb-8'>{{ item.descriptionCard.length > 123 ? item.descriptionCard.slice(0, 120) + '...' : item.descriptionCard }}</p>

        <div class="mt-auto">

          <div class="flex flex-wrap items-center">
            <template v-if="!!item.startDate">
              <svg class='flex-no-shrink' width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="#FF5F00" fill-rule="nonzero">
                  <path d="M7.66666667,15 C3.6165785,15 0.333333335,11.7167548 0.333333335,7.66666667 C0.333333335,3.6165785 3.6165785,0.333333335 7.66666667,0.333333335 C11.7167548,0.333333335 15,3.6165785 15,7.66666667 C15,11.7167548 11.7167548,15 7.66666667,15 Z M7.66666667,13.6666667 C10.9803752,13.6666667 13.6666667,10.9803752 13.6666667,7.66666667 C13.6666667,4.35295817 10.9803752,1.66666667 7.66666667,1.66666667 C4.35295817,1.66666667 1.66666667,4.35295817 1.66666667,7.66666667 C1.66666667,10.9803752 4.35295817,13.6666667 7.66666667,13.6666667 Z" id="Oval"></path>
                  <polygon id="Path" points="10.6314757 8.40371521 10.0351909 9.59628479 7 8.07868933 7 3.66666667 8.33333333 3.66666667 8.33333333 7.25464401"></polygon>
                </g>
              </svg>
              <div class="text-xs ml-1-5 text-black mt-0-5 flex-no-shrink uppercase font-euclid-medium font-medium mr-3 xl:mr-5">{{ formatDate(item.startDate, 'date') }}</div>
            </template>

            <template v-if="hasProgramLocation">
              <svg class='flex-no-shrink' width="13px" height="15px" viewBox="0 0 13 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path fill="#FF5F00" d="M11,6.33333333 C11,3.75600451 8.91066217,1.66666666 6.33333333,1.66666666 C3.7560045,1.66666666 1.66666666,3.7560045 1.66666666,6.33333333 C1.66666666,7.99791587 3.20982103,10.3211833 6.33765398,13.232374 C9.42328311,10.4101921 11,8.02069469 11,6.33333333 Z M5.88581371,14.6274695 C2.20045478,11.289786 0.333333335,8.56762765 0.333333335,6.33333333 C0.333333335,3.01962484 3.01962484,0.333333335 6.33333333,0.333333335 C9.64704184,0.333333335 12.3333334,3.01962484 12.3333334,6.33333333 C12.3333334,8.59193595 10.4182775,11.4018778 6.77565811,14.6321251 L6.32864981,15.0285287 L5.88581371,14.6274695 Z M6.33333333,8.99999999 C4.860574,8.99999999 3.66666666,7.80609266 3.66666666,6.33333333 C3.66666666,4.860574 4.860574,3.66666666 6.33333333,3.66666666 C7.80609266,3.66666666 8.99999999,4.860574 8.99999999,6.33333333 C8.99999999,7.80609266 7.80609266,8.99999999 6.33333333,8.99999999 Z M6.33333333,7.66666666 C7.069713,7.66666666 7.66666666,7.069713 7.66666666,6.33333333 C7.66666666,5.59695366 7.069713,4.99999999 6.33333333,4.99999999 C5.59695366,4.99999999 4.99999999,5.59695366 4.99999999,6.33333333 C4.99999999,7.069713 5.59695366,7.66666666 6.33333333,7.66666666 Z"></path>
              </svg>
              <div class="text-xs ml-1-5 text-black mt-0-5 flex-no-shrink uppercase font-euclid-medium font-medium">
                <span v-for="(location, index) in item.programLocation"
                  :key="index"
                >
                  {{ location.title }}
                  <span v-if="index != item.programLocation.length - 1">, </span>
                </span>
              </div>
            </template>
          </div>

        </div>
      </div>

      </div>
    </a>


  </transition>
</template>

<script>
import Vue from 'vue';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import moment from 'moment';

moment.locale('en');

export default {
  name: 'CategorySearchItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    sectionType: {
      type: Array,
      default: []
    },
  },
  computed: {
    hasProgramCategory() {
      return this.item.programCategory && this.item.programCategory.length > 0;
    },
    hasProgramLabel() {
      return this.item.programLabel && this.item.programLabel.length > 0;
    },
    hasProgramLocation() {
      return this.item.programLocation && this.item.programLocation.length > 0;
    },    
  },  
  methods: {
    formatDate: function(date, format) {
      const momentDate = moment(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD MMMM');
        case 'monthYear':
          return momentDate.format('MMM YYYY');
        break;
      }
    }
  }
};
</script>
