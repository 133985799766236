import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment';
import { configureApi, apiToken, apiUrl, getQueryParam, executeQuery } from './searchCommon';

import CategorySearchMeta from '../../vue/CategorySearchMeta.vue'
import CategorySearchList from '../../vue/CategorySearchList.vue'
import CategoryFilter from '../../vue/CategoryFilter.vue'

const siteLang = document.documentElement.lang;

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    totalCount: 0,
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchCategory: {},
    searchCategories: [],
    searchResults: [],
    searchSorting: 'postdate desc',
    startDate: {},
    relatedProgramIds: null,
    siteLang,
    isLoading: true,
    isReady: false
  };
}

let filterChangeTimer, excludedIdsTimer;

let searchApp = function () {

  // What to search for
  const searchSections = JSON.parse(document.getElementById('searchApp').dataset.section);
  const searchEntries = JSON.parse(document.getElementById('searchApp').dataset.entries);
  const searchTransform = JSON.parse(document.getElementById('searchApp').dataset.transform);



  let searchQueries = ''
  _.each(searchEntries, (entryType) => {
    searchQueries = searchQueries +
      `
      ... on ${entryType} {
        id
        title
        url
        startDate
        postDate
        image {
          url @transform (width: 300)
        }
        programCategory {
          title
        }
        programLanguage {
          title
        }
        programLocation {
          title
        }
        programType {
          title
        }
        programLabel {
          title
        }
        descriptionCard
        startDate
        registrationEndDate
        ${searchTransform.join('\n')}
      }`
  });

  // Define the fragment separately
  const searchableFieldsFragment = `
  fragment searchableFields on EntryInterface {
      ${searchQueries}
  }`;

  // The query to search for entries in Craft
  const searchQuery = `
  ${searchableFieldsFragment}

query searchQuery($site: [String], $sections: [String], $startDate: [QueryArgument], $needle: String, $limit: Int, $offset: Int, $orderBy: String, $idNot: [QueryArgument]) {
    entries(site: $site, limit: $limit, offset: $offset, orderBy: $orderBy, search: $needle, section: $sections, startDate: $startDate, id: $idNot) {
        ...searchableFields
    }
    entryCount(limit: $limit, search: $needle, offset: $offset)
}`;



  new Vue({
    el: document.getElementById('searchApp'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      CategorySearchList,
      CategorySearchMeta,
      CategoryFilter,
    },
    beforeCreate: function () {
    },
    created: function () {
      // this.selected.location = !!$eventLanding.data('location') && $eventLanding.data('location');
      let categoryIds = [];
      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');
      const startParam = getQueryParam('start');
      const endParam = getQueryParam('end');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if (!!categoryParam) {
        this.searchCategory.slug = categoryParam;
      }
      if (!!categoryIdParam) {
        const categoryIds = categoryIdParam.map(id => parseInt(id));
        this.searchCategories = categoryIds;
      }
      if (!!startParam && !!endParam) {
        this.startDate = { start: getQueryParam('start'), end: getQueryParam('end') };
      }
      this.sectionType = searchSections;
    },
    mounted: function () {
      this.performSearch();
    },
    updated: function () {
    },
    destroyed: function destroyed() {
    },
    watch: {
      searchQuery: function (val, oldVal) {
        this.performSearch();
      },
      searchCategories: {
        handler: function (val, oldVal) {
          this.performSearch();
        },
        deep: true
      },
      startDate: {
        handler: function (val, oldVal) {
          this.performSearch();
        },
        deep: true
      },
      searchSorting: function () {
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      excludedIds() {
        clearTimeout(excludedIdsTimer);
        let self = this;
        let query = `
  query searchQuery($sections: [String!]) {
    entries(section: $sections, search: "relatedProgrammes:*") {
      ... on programs_program_Entry {
        relatedProgrammes {
          id
        }
      }
    }
  }`;
        excludedIdsTimer = setTimeout(function () {
          executeQuery(self.searchApi, query, { sections: searchSections }, (data) => {
            if (data && data.data && data.data.entries) {
              const dataPath = data.data;
              self.relatedProgramIds = _.compact(_.map(_.flatten(_.map(dataPath.entries, 'relatedProgrammes')), 'id'));
              self.performSearch();
            } else {
              console.error("Error processing GraphQL response:", data);
            }
          });
        }, 500);
      },
      performSearch() {
        let self = this;

        if (!self.relatedProgramIds) {
          self.excludedIds();
          return true;
        }

        self.isLoading = true;
        self.setHistory();

        let searchTitle = !!self.searchQuery ? `title:'${self.searchQuery}' OR relatedProgrammes:'${self.searchQuery}' OR contentMatrix:'${self.searchQuery}'` : '';

        let searchCategoriesString = '';
        if (!_.isEmpty(self.searchCategories)) {
          _.map(self.searchCategories, o => {
            searchCategoriesString += (`${_.camelCase(_.get(o, 'parentCategory.handle'))}:"*${o.title}*" OR ${_.camelCase(_.get(o, 'parentCategory.handle'))}::"*${o.title}*" `)
          })
        } else {
          searchCategoriesString = '';
        }
        const startDateString = !_.isEmpty(_.get(self, 'startDate')) ? ["and", `>= ${moment(self.startDate.start).format('YYYY-MM-DD')}`, `<= ${moment(self.startDate.end).format('YYYY-MM-DD')}`] : [];

        // Set the variables we will pass in to our query
        let variables = {
          sections: searchSections,
          needle: _.compact([searchTitle, searchCategoriesString]).join(' '),
          startDate: startDateString,
          orderBy: self.searchSorting,
          site: siteLang
        };

        if (self.relatedProgramIds.length > 0) {
          _.assign(variables, { idNot: ['not', ...self.relatedProgramIds] });
        }

        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function () {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            self.searchResults = dataPath.entries;
            self.totalCount = dataPath.entryCount;

            if (!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function () {
        let self = this;
        let paramString = '';
        if (!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if (!!_.get(self.searchCategories[0], 'slug')) {
          _.forEach(self.searchCategories, o => {
            paramString += !!paramString ? ('&cat=' + o.slug) : (paramString += '?cat=' + o.slug);
            paramString += !!paramString ? ('&cat-id=' + o.id) : (paramString += '?cat-id=' + o.id);
          })
        }
        if (!_.isEmpty(self.startDate)) {
          paramString += !!paramString ? ('&start=' + moment(self.startDate.start).format('YYYY-MM-DD')) : (paramString += '?start=' + moment(self.startDate.start).format('YYYY-MM-DD'));
          paramString += !!paramString ? ('&end=' + moment(self.startDate.end).format('YYYY-MM-DD')) : (paramString += '?end=' + moment(self.startDate.end).format('YYYY-MM-DD'));
        }
        if (window.history && window.history.replaceState) {
          let pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          let url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function () {
        let top = 0;

        setTimeout(function () {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
    },
  });
};

!!document.getElementById('searchApp') && searchApp();
