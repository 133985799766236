// assign HTMLCollection with parents of images with objectFit to variable
const tabContainer = document.querySelectorAll('[data-tab-container]');

// Loop through HTMLCollection
for (let i = 0; i < tabContainer.length; i++) {
  const toggle = tabContainer[i].querySelectorAll('[data-tab-toggle]')
  const toggleItems = tabContainer[i].querySelectorAll('[data-tab-content]')

  if (toggle !== null) {
    for (let j = 0; j < toggle.length; j++) {
      toggle[j].onclick = function (e) {
        e.preventDefault();
        for (let k = 0; k < toggle.length; k++) {
          if (toggle[k].classList.contains('program-landing-nav')) {
            if (toggle[k].classList.contains('has-image')) {
              toggle[k].classList.remove('selected-item-white');
            } else {
              toggle[k].classList.remove('selected-item-grey');
            }
          } else {
            toggle[k].classList.remove('text-grey-dark');
            toggle[k].classList.add('text-grey');
          }
          toggleItems[k].classList.add('hidden');
        }
        if (toggle[j].classList.contains('program-landing-nav')) {
          if (toggle[j].classList.contains('has-image')) {
            toggle[j].classList.add('selected-item-white');
          } else {
            toggle[j].classList.add('selected-item-grey');
          }
        } else {
          toggle[j].classList.add('text-grey-dark');
          toggle[j].classList.remove('text-grey');
        }
        toggleItems[j].classList.remove('hidden');
      }
    }
  }
}

if(window.location.href.includes("/fi/")) {
  const tabBtn = document.querySelectorAll('[data-tab-toggle]')

  if(tabBtn) {
    tabBtn.forEach(function(btn) {
      if(btn.textContent.includes("Fin")) {
        btn.click()
      }
    })
  }
}