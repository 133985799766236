// Get how many decimals
Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  var str = this.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
};

// check if element is in viewport
function isInViewport(elem) {
  let bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
    (window.innerWidth || document.documentElement.clientWidth)
  );
}

// Easing function
function easeOutCubic(t) {
  const t1 = t - 1;
  return t1 * t1 * t1 + 1;
}

// Numbers animation start here
const numbers = document.querySelectorAll(".number-animation");
const animTime = 1000;

// Loop throuch all number animation elements
numbers.forEach((numberElem) => {
  const originalText = numberElem.innerText;
  // Check if we have prefix, suffix and number in the string of the element
  const regex = /^(.*?)([0-9,\.]+)(.*?)$/;
  const match = originalText.match(regex);
  if (!match) {
    return false;
  }
  const prefix = match[1];
  const number = Number(match[2].replaceAll(",", "."));
  const suffix = match[3];
  const decimalSeparator = match[2].includes(",") ? "," : ".";
  const decimalsAmount = number.countDecimals();
  // Check if already passed the item to only trigger functions once
  let scrollPassedItem = false;
  // Trigger animation
  function triggerAnimation() {
    // check if we have passed the item offset
    if (isInViewport(numberElem)) {
      // only do this once
      if (!scrollPassedItem) {
        // Interval
        const initTime = performance.now();
        let interval = setInterval(function () {
          // Calculate the time so we do not go over animation time
          let t = (performance.now() - initTime) / animTime;

          // Format the current number value so it matches the original number value
          let currentValue = (easeOutCubic(t) * number)
            .toFixed(decimalsAmount)
            .toString()
            .replaceAll(".", decimalSeparator);

          // Output the text with the new value
          numberElem.textContent = prefix + currentValue + suffix;

          // Animation time is over, so lets put back the original text
          if (t >= 1) {
            clearInterval(interval);
            numberElem.textContent = originalText;
          }
        }, 50);

        // set scrollPassed to true, so we do not do the same animation again
        scrollPassedItem = true;
        numberElem.classList.add("animated");
      }
    }
  }
  // on scrolling
  window.addEventListener("scroll", function () {
    triggerAnimation();
  });
  // on document ready
  triggerAnimation();
});
