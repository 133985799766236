var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "md:-mx-4",
      style: { minHeight: _vm.isLoading ? _vm.listMinHeight() : "auto" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "absolute w-full h-full pin-x mx-auto flex flex-wrap content-start",
          staticStyle: { "z-index": "-1" }
        },
        _vm._l(6, function(n) {
          return _c("card-loading-indicator", {
            key: n,
            attrs: {
              "is-loading": _vm.isLoading,
              "is-ready": _vm.isReady,
              "section-type": _vm.sectionType
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.items.length > 0
        ? _c(
            "div",
            {
              staticClass: "w-full pb-6 flex flex-wrap",
              attrs: { "item-selector": ".item" }
            },
            _vm._l(_vm.items, function(item, key) {
              return _c(
                "div",
                {
                  key: key,
                  staticClass: "w-full md:w-1/2 xxl:w-1/3 md:px-3 mb-6"
                },
                [
                  _c("category-search-item", {
                    attrs: {
                      item: item,
                      "is-loading": _vm.isLoading,
                      "section-type": _vm.sectionType
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fadeIn", mode: "in-out" } },
        [
          !_vm.isLoading && _vm.items.length === 0
            ? [
                _c(
                  "h4",
                  {
                    staticClass:
                      "font-euclid-bold mb-6 leading-slight text-xl font-normal text-grey-dark max-w-2xs mx-auto py-8 text-center"
                  },
                  [_vm._v("No programmes found")]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }