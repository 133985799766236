// handle cookies easier
import * as Cookies from 'tiny-cookie'

// Cookie policy
if (!!document.getElementById('cookies')) {
  const cookieBanner = document.getElementById('cookies')
  const closeCookie = document.getElementById('js-accept-cookies')

  // we do not have a cookie
  if (!Cookies.get('accept-cookies')) {
    cookieBanner.classList.add('block')
    cookieBanner.classList.remove('hidden')

    // close cookie banner
    closeCookie.onclick = function(e) {
      e.preventDefault()

      const secureCookie = location.protocol === 'https:'

      cookieBanner.classList.remove('block')
      cookieBanner.classList.add('hidden')
      Cookies.set('accept-cookies', true, {
        expires: '30D',
        secure: secureCookie,
      })

      return false
    }
  }
}
