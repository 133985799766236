import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import { configureApi, apiToken, apiUrl, getQueryParam, executeQuery } from './searchCommon';
import CategorySearchMeta from '../../vue/CategorySearchMeta.vue'
import SearchPageList from '../../vue/SearchPageList.vue'
import Pagination from '../../vue/Pagination.vue'

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchResults: [],
    searchSorting: 'postdate desc',
    pagination: {
      totalCount: 0,
      pages: [],
      hasNext: false,
      hasPrev: false,
      nextPageUrl: '',
      prevPageUrl: '',
      elementsPerPage: 12,
      currentPage: 1
    },
    isLoading: true,
    isReady: false,
    window: {
      width: 0,
      height: 0
    }
  };
}

let filterChangeTimer;

let generalSearch = function () {

  const searchSite = document.getElementById('searchPage').dataset.site;

  // What to search for
  const searchEntries = ['newsInsights_article_Entry', 'newsInsights_resource_Entry', 'people_person_Entry', 'programs_program_Entry', 'programs_programmeNew_Entry', 'homepage_homepage_Entry', 'contact_contact_Entry', 'customDesign_customDesign_Entry', 'newsInsightsLanding_newsInsightsLanding_Entry', 'pages_page_Entry', 'pages_landingPage_Entry', 'peopleLanding_peopleLanding_Entry', 'privacyPolicy_privacyPolicy_Entry', 'programsLanding_programsLanding_Entry'];
  let searchQueries = ''
  _.each(searchEntries, (entryType) => {
    searchQueries = searchQueries +
      `
      ... on ${entryType} {
        typeHandle
        id
        title
        url${['newsInsights_article_Entry', 'people_person_Entry', 'programs_program_Entry'].includes(entryType) ? `
          image {
            url @transform (width: 300)
          }
        ` : ''}
        ${entryType === 'newsInsights_article_Entry' ? `
          newsCategory {
            title
          }
        ` : ''}
        ${entryType === 'programs_program_Entry' ? `
          programCategory {
            title
          }
        ` : ''}
        ${entryType === 'people_person_Entry' ? `
          peopleCategory {
            title
          }
          jobTitle
          phoneNumber
          email
          links {
            ... on links_BlockType {
              linkName
              linkUrl
            }
          }
        ` : ''}
      }`
  });

  // The query to search for entries in Craft
  const searchQuery =
    `
query searchQuery($needle: String, $limit: Int, $offset: Int, $orderBy: String, $site: [String]) {
  entries(limit: $limit, search: $needle, orderBy: $orderBy, site: $site, offset: $offset) {
    ${searchQueries}
  }
  entryCount(limit: $limit search: $needle offset: $offset)
}
`;


  new Vue({
    el: document.getElementById('searchPage'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      SearchPageList,
      CategorySearchMeta,
      Pagination,
    },
    beforeCreate: function () {
    },
    created: function () {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if (!!categoryParam) {
        this.searchCategory.slug = categoryParam;
      }
      if (!!categoryIdParam) {
        this.searchCategory.id = categoryIdParam;
      }
      if (!!pageParam) {
        this.pagination.currentPage = parseInt(pageParam);
      }
    },
    mounted: function () {
      this.performSearch();
    },
    updated: function () {
    },
    destroyed: function () {
      document.removeEventListener('click', this.closeSearchDropdown);
      window.removeEventListener('resize', this.handleResize);
    },
    watch: {
      'pagination.totalPages': function (val) {
        this.updatePagination();
      },
      'pagination.currentPage': function (val) {
        this.scrollup();
        this.pagination.offset = (this.pagination.currentPage - 1) * this.pagination.elementsPerPage;
        this.performSearch();
      },
      searchQuery: function (val, oldVal) {
        if (!!oldVal && val !== oldVal) {
          this.pagination = _.get(getcategorySearchLandingData(), 'pagination');
        }
        this.performSearch();
      },
      searchSorting: function () {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      performSearch() {
        let self = this;

        if (self.searchQuery === '') {
          self.searchResults = [];
          return true;
        }

        self.isLoading = true;
        self.setHistory();
        const searchTitle = !!self.searchQuery ? `title:'${self.searchQuery}' OR contentMatrix:'${self.searchQuery}'` : '';
        const searchCategory = !!self.searchQuery && `categories:"${self.searchQuery}" OR categories::"${self.searchQuery}"`;
        const searchTag = !!self.searchTag && `tags:${self.searchQuery} OR tags::${self.searchQuery}`;

        const searchString = _.compact([searchTitle, searchCategory, searchTag]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
          needle: searchString,
          limit: self.pagination.elementsPerPage || 24,
          offset: (self.pagination.currentPage - 1) * self.pagination.elementsPerPage,
          orderBy: self.searchSorting,
          site: searchSite
        };

        // Execute the query
        filterChangeTimer = setTimeout(function () {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            self.searchResults = dataPath.entries;
            self.pagination.totalCount = dataPath.entryCount;
            self.pagination.totalPages = Math.ceil(Number(dataPath.entryCount) / (self.pagination.elementsPerPage || 24));;

            if (!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function () {
        var self = this;
        var paramString = '';
        if (!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if (!!_.get(self.searchCategory, 'slug')) {
          paramString += !!paramString ? ('&cat=' + self.searchCategory.slug) : (paramString += '?cat=' + self.searchCategory.slug);
          paramString += !!paramString ? ('&cat-id=' + self.searchCategory.id) : (paramString += '?cat-id=' + self.searchCategory.id);
        }
        if (!!self.pagination.currentPage) {
          paramString += !!paramString ? ('&page=' + self.pagination.currentPage) : (paramString += '?page=' + self.pagination.currentPage);
        }
        if (window.history && window.history.replaceState) {
          var pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          var url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function () {
        let top = 0;

        setTimeout(function () {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
      updatePagination() {
        const { pagination, currentPage, totalPages } = this;
        pagination.pages = [];
        for (let i = 0; i < totalPages; i++) {
          const index = i + 1;
          pagination.pages.push({
            text: index,
            url: this.getPageUrl(index),
            current: index === currentPage
          });
        }
        pagination.hasNext = currentPage < totalPages;
        pagination.hasPrev = currentPage > 1;
      },
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      }
    },
  });
};

!!document.getElementById('searchPage') && generalSearch();
