<template>
  <div class="sorting mb-0 sm:min-w-1/2 flex items-center justify-center md:justify-end font-euclid text-sm xl:text-base ">
    <div class="pr-3">{{ vueTranslations.sorting }}</div>
    <div class="relative max-w-xxxs">
      <select
        :sorting.sync="sorting"
        @change="$emit('update:sorting', $event.target.value)"
        class="block appearance-none w-full bg-transparent pr-12 focus:outline-none font-euclid-bold cursor-pointer"
      >
        <option v-for="(option, value) in options" :key="value" :value="option.value" :selected="option.value === sorting">{{ option.title }}</option>
      </select>
      <div class="pointer-events-none absolute pin-y pin-r flex items-center text-grey-dark">
        <span class="h-3 inline-block text-grey-dark">
          <svg class="stroke-current h-3 w-3" viewBox="0 0 768 768">
            <path fill="none" stroke-linejoin="miter" stroke-linecap="square" stroke-miterlimit="4" stroke-width="96" d="M95.999 240l288.001 288.001 288.001-288.001"></path>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'SearchSorting',
  props: [
    'sorting',
    'options'
  ],
  options: {
    type: Object,
    default: [],
    required: true,
  },
  data() {
    return {
      vueTranslations
    }
  }
};
</script>
