// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss';

// Vue js
import Vue from 'vue';

// Our own helpers
import reframe from './helpers/iframe';
import './helpers/objectfitFallback';
import './helpers/classList';

// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes';
import 'picturefill';

import Rellax from 'rellax';

import Hoverintent from 'hoverintent';

// For hiding nav when scrolling
import Headroom from 'headroom.js';

import './components/tab';
import './components/toggle';
import './components/cookieBanner';
import './components/categorySearch';
import './components/generalSearch';
import './components/searchPageSearch';
import './components/searchPrograms';
import './components/lockTrigger';
import './components/carousel';
import './components/counterEffect';

import eyeEffect from './components/eyeEffect';
import plopEffect from './components/plopEffect';

// Window onload (after all dom and images are loaded completely)
window.onload = function () {
  // responsive iframes
  reframe(document.querySelectorAll('.wysiwyg iframe')) // Pass a live node list

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')

  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table')
  if (tables.length) {
    if (!('length' in tables)) tables = [tables]
    for (let i = 0; i < tables.length; i += 1) {
      const table = tables[i]

      const div = document.createElement('div')
      div.className = 'table-container'

      table.parentNode.insertBefore(div, table)
      table.parentNode.removeChild(table)
      div.appendChild(table)
    }
  }

  if (document.getElementById('#searchApp')) {
    document.addEventListener('click', closeDropdown);
  }

  let toggleItems = document.getElementsByClassName("filterDropdownToggle");
  for (let i = toggleItems.length - 1; i >= 0; i--) {
    toggleItems[i].addEventListener("click", toggleDropdown);
  }

  eyeEffect('#eye-effect .eye', 150);
  plopEffect('.plop-effect', 'is-loaded');

  // Click intent on header dropdown
  let hoverElements = document.querySelectorAll("[data-hoverintent]");
  let activeElement = null; // To keep track of the currently active element

  // Click intent on header dropdown
  hoverElements.forEach(element => {
    element.addEventListener('click', function (event) {
      // Stop the click event from immediately propagating to document
      event.stopPropagation();

      const targets = document.querySelectorAll(element.getAttribute('data-hoverintent'));

      // Toggle only if the clicked element is exactly the same as the active element, but skip this if the click is within the dropdown content
      if (activeElement === element && !event.target.closest('.header-dropdown')) {
        toggleElements(targets, false); // Toggle the visibility of the dropdown
        activeElement = null; // Reset the active element since we're closing the dropdown
      } else {
        // Logic to handle opening a new dropdown or switching to a different one
        if (activeElement) {
          // Close the previously active dropdown
          const activeTargets = document.querySelectorAll(activeElement.getAttribute('data-hoverintent'));
          toggleElements(activeTargets, false);
        }
        // Open the new dropdown and set it as the active element
        toggleElements(targets, true);
        activeElement = element;
      }
    });
  });

  document.addEventListener('click', function (event) {
    // Check if the click is on a first-level node (another element with the 'data-hoverintent' attribute)
    const clickedFirstLevelNode = event.target.closest('[data-hoverintent]');

    // Determine if the click occurred inside any '.header-dropdown' element
    const clickInsideDropdown = event.target.closest('.header-dropdown');

    if (activeElement) {
      if (clickedFirstLevelNode && clickedFirstLevelNode !== activeElement) {
        // A different first-level node was clicked, which will be handled by its own click event listener.
      } else if (!clickInsideDropdown) {
        // The click is outside of any '.header-dropdown' containers.
        const targets = document.querySelectorAll(activeElement.getAttribute('data-hoverintent'));
        toggleElements(targets, false);
        activeElement = null; // Reset active element
      }
    }
  });

  function toggleElements(targets, open) {
    const action = open ? 'add' : 'remove';
    document.querySelectorAll('.site-navigation__container')[0].classList[action]('hover-in');
    document.querySelectorAll('.slide-menu-overlay')[0].classList[action]('slide-menu-overlay--on');

    for (let i = 0; i < targets.length; i += 1) {
      targets[i].classList[action]('hover-in');
    }
  }



  let header = document.querySelector(".sub-nav");
  let mainHeader = document.querySelector("#primary-navigation");

  if (!!header) {
    window.onscroll = function () { myFunction() };
    let sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > (sticky - 100)) {
        mainHeader.classList.add("hide-primary-navigation");
      } else {
        mainHeader.classList.remove("hide-primary-navigation");
      }
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }
}

// grab an element
let myElement = document.querySelector("#primary-navigation");
// options
var options = {
  // scroll tolerance in px before state changes
  tolerance: {
    up: 10,
    down: 0
  },
}
// construct an instance of Headroom, passing the element
let headroom = new Headroom(myElement, options);
// initialise
headroom.init();

if (document.querySelectorAll('.rellax').length) {
  let rellax = new Rellax('.rellax', {
    speed: -2,
    center: false,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: false
  })
}

document.getElementById('hamburger-1').onclick = function () {
  document.querySelector("#nav-dropdown").style.display = "block";
}

document.getElementById('nav-close').onclick = function () {
  document.querySelector("#nav-dropdown").style.display = "none";
}

//CONTACT BOX
const contactBoxBtn = document.getElementById('contact-box-button');;

if (!!contactBoxBtn) {
  contactBoxBtn.onclick = function () {
    document.getElementById("contact-box").style.display = "flex";
  }
  document.getElementById('contact-box-close').onclick = function () {
    document.getElementById("contact-box").style.display = "none";
  }
}

function toggleDropdown(event) {
  let dropdownItems = document.getElementsByClassName('filterDropdownItem');

  for (let i = dropdownItems.length - 1; i >= 0; i--) {
    if (
      !(dropdownItems[i].previousElementSibling === event.target ||
        dropdownItems[i].previousElementSibling === event.target.parentNode)
    ) {
      dropdownItems[i].classList.add('hidden');
    }
  }

  if (this.nextElementSibling.classList.contains('hidden')) {
    this.nextElementSibling.classList.remove('hidden');
  } else {
    this.nextElementSibling.classList.add('hidden');
  }
}

document.addEventListener('click', function (event) {
  // Check if the click is outside the dropdown
  if (!event.target.closest('.filterDropdown') && !event.target.closest('.filterDropdownToggle')) {
    closeDropdown(event);
  }
});

function closeDropdown(event) {
  // Assuming the dropdown items should close if the click is outside
  let shouldClose = !event.target.classList.contains('filterDropdownToggle') &&
    !event.target.parentNode.classList.contains('filterDropdownToggle');

  if (shouldClose) {
    let dropdownItems = document.getElementsByClassName('filterDropdownItem');
    for (let i = 0; i < dropdownItems.length; i++) {
      dropdownItems[i].classList.add('hidden');
    }
  }
}

