var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vCalendarIsDefined
    ? _c(
        "div",
        {
          staticClass: "relative xl:mr-3 flex",
          class: { "mt-4": _vm.isMobile, "mt-3 h-9-5": !_vm.isMobile }
        },
        [
          _c(
            "form",
            {
              staticClass: "w-full xl:w-auto",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex h-full text-white relative bg-blue-dark",
                  class: { "border border-solid border-white": _vm.isMobile }
                },
                [
                  _c("v-date-picker", {
                    staticClass:
                      "font-euclid flex self-stretch min-w-44 justify-between text-white w-full xl:w-56 capitalize",
                    attrs: {
                      mode: "range",
                      theme: _vm.theme,
                      locale: _vm.vueTranslations.calendarLocale
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var inputProps = ref.inputProps
                            var inputEvents = ref.inputEvents
                            var isDragging = ref.isDragging
                            return _c(
                              "input",
                              _vm._g(
                                _vm._b(
                                  {
                                    ref: "dateInput",
                                    class: [
                                      "calendar-picker appearance-none cursor-pointer font-euclid w-full bg-blue-dark px-4 text-white text-normal pr-10 " +
                                        (_vm.isMobile ? "h-14-5" : "h-9-5") +
                                        " " +
                                        (isDragging
                                          ? "text-grey"
                                          : "text-white")
                                    ],
                                    attrs: {
                                      id: "date",
                                      placeholder:
                                        _vm.vueTranslations.any +
                                        " " +
                                        _vm.vueTranslations.startingDate,
                                      readonly: ""
                                    }
                                  },
                                  "input",
                                  inputProps,
                                  false
                                ),
                                inputEvents
                              )
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      1527284323
                    ),
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  }),
                  _vm._v(" "),
                  _vm.date == null
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "bg-blue-dark absolute pin-t pin-r pointer-events-none",
                          class: {
                            "h-14-5 px-6": _vm.isMobile,
                            "h-9-5 px-4": !_vm.isMobile
                          },
                          attrs: { type: "button" }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "pointer-events-none text-orange",
                              attrs: {
                                width: "9px",
                                height: "6px",
                                viewBox: "0 0 9 6",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "#FF5F00",
                                  d:
                                    "M5.375,4.0703125 L8.773438,0.8085938 C8.877605,0.7044266 8.98177,0.7044266 9.085938,0.8085938 L9.691406,1.3945312 C9.795573,1.4986984 9.795573,1.6028641 9.691406,1.7070312 L5.53125,5.6914062 C5.492187,5.7304689 5.440104,5.75 5.375,5.75 C5.309896,5.75 5.257813,5.7304689 5.21875,5.6914062 L1.058594,1.7070312 C0.954427,1.6028641 0.954427,1.4986984 1.058594,1.3945312 L1.664062,0.8085938 C1.76823,0.7044266 1.872395,0.7044266 1.976562,0.8085938 L5.375,4.0703125 Z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.date !== null
                    ? _c(
                        "button",
                        {
                          staticClass: "bg-blue-dark absolute pin-t pin-r",
                          class: {
                            "h-14-5 px-6": _vm.isMobile,
                            "h-9-5 px-4": !_vm.isMobile
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.date = null
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "9px",
                                height: "9px",
                                viewBox: "0 0 6 5",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    fill: "#FF5F00",
                                    "fill-rule": "nonzero"
                                  }
                                },
                                [
                                  _c("polygon", {
                                    attrs: {
                                      id: "Line-Copy",
                                      transform:
                                        "translate(3.000000, 2.500000) rotate(-315.000000) translate(-3.000000, -2.500000) ",
                                      points:
                                        "6 2 1.8189894e-12 2 1.8189894e-12 3 6 3"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("polygon", {
                                    attrs: {
                                      id: "Line-Copy-2",
                                      transform:
                                        "translate(3.000000, 2.500000) rotate(-45.000000) translate(-3.000000, -2.500000) ",
                                      points:
                                        "6 2 1.8189894e-12 2 1.8189894e-12 3 6 3"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }