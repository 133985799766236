import Vue from 'vue'

let searchPrograms = function () {
  new Vue({
    el: '#searchPrograms',
    data: {
      message: 'this is the message',
    }
  })
}

!!document.getElementById('searchPrograms') && searchPrograms();