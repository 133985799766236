var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-wrap justify-between text-center md:text-left font-euclid text-sm xl:text-base",
      class:
        _vm.sectionType[0] === "events" ||
        _vm.sectionType[0] === "searchLanding"
          ? "mb-0"
          : "mb-3"
    },
    [
      _c(
        "div",
        {
          staticClass: "w-2/5 text-left",
          class:
            _vm.sectionType[0] === "events" ||
            _vm.sectionType[0] === "searchLanding"
              ? "mb-0"
              : "mb-3 md:mb-0"
        },
        [
          _vm.itemsAmount > 0
            ? _c("div", [
                _vm.withPagination
                  ? _c("div", [
                      _c(
                        "span",
                        { staticClass: "pr-1 font-euclid-bold text-lg" },
                        [
                          _vm._v(
                            _vm._s(_vm.itemOffset + 1) +
                              " – " +
                              _vm._s(_vm.itemsAmount)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "leading-loose" }, [
                        _vm._v("(" + _vm._s(_vm.totalItems) + ")")
                      ])
                    ])
                  : _c("div", [
                      _c("span", { staticClass: "leading-loose text-lg" }, [
                        _vm._v(_vm._s(_vm.itemsAmount) + " results")
                      ])
                    ])
              ])
            : _c("div", [
                _c("span", { staticClass: "pr-1 font-euclid-bold text-lg" }, [
                  _vm._v("0")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "leading-loose text-lg" }, [
                  _vm._v("results")
                ])
              ])
        ]
      ),
      _vm._v(" "),
      _c("search-sorting", {
        attrs: { sorting: _vm.searchSorting, options: _vm.sortingOptions },
        on: {
          "update:sorting": function($event) {
            _vm.searchSorting = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }