<template>
  <div
    v-if="vCalendarIsDefined"
    class="relative xl:mr-3 flex"
    :class="{'mt-4': isMobile, 'mt-3 h-9-5': !isMobile}"
  >
    <form @submit.prevent class="w-full xl:w-auto">
      <div
        class="flex h-full text-white relative bg-blue-dark"
        :class="{'border border-solid border-white': isMobile}"
      >
        <v-date-picker
          mode="range"
          v-model="date"
          class="font-euclid flex self-stretch min-w-44 justify-between text-white w-full xl:w-56 capitalize"
          :theme="theme"
          :locale="vueTranslations.calendarLocale"
        >
          <!--Custom Input Slot-->
          <input
            id="date"
            :placeholder="vueTranslations.any + ' ' + vueTranslations.startingDate"
            slot-scope="{ inputProps, inputEvents, isDragging }"
            :class="[`calendar-picker appearance-none cursor-pointer font-euclid w-full bg-blue-dark px-4 text-white text-normal pr-10 ${isMobile ? 'h-14-5' : 'h-9-5' } ${ isDragging ? 'text-grey' : 'text-white' }`]"
            v-bind="inputProps"
            v-on="inputEvents"
            readonly
            ref="dateInput"
          >
        </v-date-picker>


        <button
          type="button"
          class="bg-blue-dark absolute pin-t pin-r pointer-events-none"
          :class="{'h-14-5 px-6': isMobile, 'h-9-5 px-4': !isMobile}"
          v-if="date == null"
        >
          <svg class="pointer-events-none text-orange" width="9px" height="6px" viewBox="0 0 9 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path fill="#FF5F00" d="M5.375,4.0703125 L8.773438,0.8085938 C8.877605,0.7044266 8.98177,0.7044266 9.085938,0.8085938 L9.691406,1.3945312 C9.795573,1.4986984 9.795573,1.6028641 9.691406,1.7070312 L5.53125,5.6914062 C5.492187,5.7304689 5.440104,5.75 5.375,5.75 C5.309896,5.75 5.257813,5.7304689 5.21875,5.6914062 L1.058594,1.7070312 C0.954427,1.6028641 0.954427,1.4986984 1.058594,1.3945312 L1.664062,0.8085938 C1.76823,0.7044266 1.872395,0.7044266 1.976562,0.8085938 L5.375,4.0703125 Z"></path>
          </svg>
        </button>

        <button
          type="button"
          class="bg-blue-dark absolute pin-t pin-r"
          :class="{'h-14-5 px-6': isMobile, 'h-9-5 px-4': !isMobile}"
          @click="date = null"
          v-if="date !== null"
        >

          <svg width="9px" height="9px" viewBox="0 0 6 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g fill="#FF5F00" fill-rule="nonzero">
              <polygon id="Line-Copy" transform="translate(3.000000, 2.500000) rotate(-315.000000) translate(-3.000000, -2.500000) " points="6 2 1.8189894e-12 2 1.8189894e-12 3 6 3"></polygon>
              <polygon id="Line-Copy-2" transform="translate(3.000000, 2.500000) rotate(-45.000000) translate(-3.000000, -2.500000) " points="6 2 1.8189894e-12 2 1.8189894e-12 3 6 3"></polygon>
            </g>
          </svg>


        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import VCalendar from '../js/helpers/calendar';
import _ from 'lodash';

if(!!VCalendar) {
  Vue.use(VCalendar, {
    firstDayOfWeek: 2, // Monday
    masks: {
      input: ['D MMM YYYY'],
    },
    popoverVisibility: 'visible',
    datePicker: {
      popover: {
        visibility: 'click'
      }
    }
    // ...,                // ...other defaults
  })
}

export default {
  props: ['categorySelector', 'startDate', 'isMobile'],
  data() {
    return {
      date: null,
      theme: {
        container: {
          light: 'bg-blue-dark text-white border border-solid border-white',
        },
        title: {
          light: 'text-white',
        },
        weekdays: {
          light: 'text-white',
        },
        bgAccentLow: {
          light: 'bg-orange-light',
        },
        bgAccentHigh: {
          light: 'bg-orange',
        },
        bgLow: {
          light: 'bg-grey-lighter',
        },
      },
      vCalendarIsDefined: false,
      vueTranslations
    }
  },
  updated() {
    this.categorySelector('startDate', this.date)
  },
  created() {
    if (!_.isEmpty(this.startDate)) {
      this.date = {
        start: new Date(this.startDate.start),
        end: new Date(this.startDate.end),
      }
    }
    this.vCalendarIsDefined = !!VCalendar;
  },
  watch: {
    startDate: function(newVal, oldVal) {
      if (_.isEmpty(newVal)) {
        this.date = null
      }
    }
  }
}
</script>