<template>
  <transition name="fadeIn" mode="in-out">
    <div class="cardLoader px-2 xl:px-3 pb-4 xl:pb-6 w-full md:w-1/2 lg:w-1/3" v-show="isLoading || !isReady" leave-stagger="500" >
      <div class="flex flex-col h-full p-5 pt-8 pb-4 border-grey-light border-solid border">
        <div class="line-medium flex items-center"></div>
        <h3 class="line-large mt-4"></h3>
        <h3 class="line-medium mt-4"></h3>
        <h3 class="line-medium-large mt-4"></h3>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'CardLoadingIndicator',
  props: ['isLoading', 'isReady', 'sectionType']
};
</script>
