var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "li",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading"
          }
        ],
        staticClass:
          "pb-4 flex flex-wrap flex-col xxxl:flex-row border-b border-1 border-solid border-grey-light",
        class: !_vm.last && "mb-4"
      },
      [
        _c(
          "a",
          {
            staticClass: "w-full",
            class: !!_vm.item.url && "group",
            attrs: { href: _vm.item.url, title: "Go to " + _vm.item.title }
          },
          [
            _c("div", { staticClass: "flex flex-col lg:flex-row -mx-3" }, [
              _c(
                "div",
                { staticClass: "flex flex-row lg:items-top w-full px-3" },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-col h-full w-2/5 mr-5" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "relative object-fit-fallback aspect-ratio-box aspect-ratio-box--4-3 bg-black"
                        },
                        [
                          _c("img", {
                            staticClass:
                              "absolute lazyload w-full h-full block object-cover group-hover:opacity-75",
                            attrs: {
                              src:
                                !!_vm.item.image && !!_vm.item.image[0]
                                  ? _vm.item.image[0].url
                                  : "/assets/img/hanken_SSE_logo.png",
                              alt:
                                !!_vm.item.image &&
                                !!_vm.item.image[0] &&
                                _vm.item.title,
                              onerror:
                                "this.src = '/assets/img/hanken_SSE_logo.png';"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full flex flex-col pr-3" }, [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "font-euclid-bold text-xl text-grey-dark inline leading-normal mb-2"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.item.title.length > 117
                              ? _vm.item.title.slice(0, 114) + "..."
                              : _vm.item.title
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.item.typeHandle === "person"
                      ? _c("div", { staticClass: "mb-2" }, [
                          _c("p", { staticClass: "text-grey-dark" }, [
                            _vm._v(_vm._s(_vm.item.jobTitle))
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "block leading-slight text-grey-dark"
                            },
                            [_vm._v(_vm._s(_vm.item.phoneNumber))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "block leading-slight text-grey-dark",
                              staticStyle: {
                                "text-overflow": "ellipsis",
                                "white-space": "nowrap",
                                overflow: "hidden"
                              }
                            },
                            [_vm._v(_vm._s(_vm.item.email))]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.item.categories
                      ? _c(
                          "div",
                          { staticClass: "mt-auto" },
                          _vm._l(_vm.item.categories.slice(0, 2), function(
                            cat,
                            key
                          ) {
                            return _c(
                              "span",
                              {
                                key: key,
                                staticClass:
                                  "text-sm text-grey-dark inline-block"
                              },
                              [
                                _vm._v(_vm._s(cat.title)),
                                key < _vm.item.categories.slice(0, 2).length - 1
                                  ? _c(
                                      "span",
                                      { staticClass: "inline-block px-1" },
                                      [_vm._v("|")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ])
                ]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }