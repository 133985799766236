<template>
  <transition name="fadeIn" mode="in-out">
    <li :class="!last && 'mb-4'" class="pb-4 flex flex-wrap flex-col xxxl:flex-row border-b border-1 border-solid border-grey-light" v-show="!isLoading">
      <a :href="item.url" :title="`Go to ${item.title}`" class="w-full" :class="!!item.url && 'group'">
        <div class="flex flex-col lg:flex-row -mx-3">
          <div class="flex flex-row lg:items-top w-full px-3">
            <div class="flex flex-col h-full w-2/5 mr-5">
              <div class="relative object-fit-fallback aspect-ratio-box aspect-ratio-box--4-3 bg-black">
                <img
                  class="absolute lazyload w-full h-full block object-cover group-hover:opacity-75"
                  :src="!!item.image && !!item.image[0] ? item.image[0].url : '/assets/img/hanken_SSE_logo.png'"
                  :alt="!!item.image && !!item.image[0] && item.title"
                  onerror="this.src = '/assets/img/hanken_SSE_logo.png';">
              </div>
            </div>
            <div class="w-full flex flex-col pr-3">
              <!-- <div class="mb-1">
                <span class="text-sm text-grey-dark inline-block" style="margin-right: -4px;">{{ item.type.name }}</span>
              </div> -->
              <h3 class="font-euclid-bold text-xl text-grey-dark inline leading-normal mb-2">{{item.title.length > 117 ? item.title.slice(0, 114) + '...' : item.title}}</h3>
              <div v-if="item.typeHandle === 'person'" class="mb-2">
                <p class="text-grey-dark">{{ item.jobTitle }}</p>
                <p class="block leading-slight text-grey-dark">{{ item.phoneNumber }}</p>
                <p class="block leading-slight text-grey-dark" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{ item.email }}</p>
              </div>

              <div v-if="!!item.categories" class="mt-auto">
                <span
                  v-for="(cat, key) in item.categories.slice(0, 2)"
                  :key="key"
                  class="text-sm text-grey-dark inline-block">{{ cat.title }}<span v-if="key < item.categories.slice(0, 2).length - 1" class="inline-block px-1">|</span></span>
              </div>
            </div>
          </div>
        </div>
      </a>
    </li>
  </transition>
</template>

<script>
import Vue from 'vue';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import moment from 'moment';

moment.locale('en');

export default {
  name: 'SearchPageItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    last: {
      type: Boolean
    }
  },
  methods: {
    formatDate: function(date, format) {
      const momentDate = moment.unix(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM');
        break;
      }
    }
  }
};
</script>
